* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

// Variables
$just-black: #000;
$just-white: #fff;

$terminal-black: #1a1a1a;
$light-black: #141313;
$terminal-green: #8ae234;

$mobile-width: 768px;

body {
    font-family: 'Consolas', monospace;
    background-color: $just-black;
}

.menu-button {
    z-index: 5;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100px;
    width: 100px;
    background-color: transparent;
    border: none;

    &:hover {
        cursor: pointer;
    }
}

.bar {
    width: 50px;
    background-color: $just-white;
    margin: 15px auto;
    padding: 3px 0;
    transition: 0.4s;

    &:hover {
        width: 60px;
    }
}

.cross {
    &:first-child {
        transform: translate(0, 20px) rotate(45deg);
    }

    &:nth-child(2) {
        opacity: 0;
    }

    &:last-child {
        transform: translate(0, -20px) rotate(-45deg);
    }
}

.sidebar {
    background: $terminal-black;
    color: $just-white;
    z-index: 4;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 50vw;
    min-width: 350px;
    max-width: 500px;
    transform: translateX(100%);
    transition: transform 0.5s ease-in-out;
}

.open {
    transform: translateX(0);
}

.nav {
    margin: 25% 50px;
    font-size: 3em;
}

.nav-item {
    list-style: none;

    a {
        color: $just-white;
        text-decoration: none;

        &:hover {
            color: $terminal-green;
        }
    }

}

.sidebar-footer {
    background-color: $light-black;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 15px 25px;
    font-size: 1em;

    li {
        display: inline-block;
        list-style-type: none;
        padding: 5px 2px;
    }

    a {
        color: $just-white;
        text-decoration: none;

        &:hover {
            color: $terminal-green;
        }
    }
}

.footer {
    z-index: 3;
    background-color: $just-black;
    border-top: 1px solid white;
    position: sticky;
    width: 70%;
    bottom: 0;
    left: 15%;
    right: 15%;
    @media screen and (max-width: $mobile-width) {
        left: 1%;
        right: 1%;
        width: 98%;
        font-size: 0.9rem;
    }
    li {
        display: inline-block;
        list-style-type: none ;
        padding: 10px 10px;
    }

    a {
        color: $just-white;
        text-decoration: none;
        &:hover {
            color: $terminal-green;
        }
    }
}

.hero {
    display: flex;
    gap: 10px;
    margin: 20px 0px;
    padding-bottom: 10px;
    width: 80%;
    color: $just-white;
    border-bottom: 2px solid $terminal-green;

    h1 {
        font-size: 2.8em;
        @media screen and (max-width: $mobile-width) {
            font-size: 2em;
        }
    }

    p {
        font-size: 1.1em;
        padding-top: 5px;
    }
}

.hero-image {
    img {
        border-radius: 50px;
    }
}

.container {
    margin: 30px 15%;

    @media screen and (max-width: $mobile-width) {
        margin: 20% 20px;
    }
}

.content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    gap: 10px;
    width: 100%;
    @media screen and (max-width: $mobile-width) {
        justify-content: center;
    }

}

.skill {
    font-size: 2em;
    padding: 10px;
    background-color: $just-black;
    color: $just-white;
    border: 2px solid $terminal-green;
    border-radius: 5px;

    @media screen and (max-width: $mobile-width) {
        padding: 4px;
        font-size: 1.2em;
    }
}

.back-btn {
    color: $just-white;
    background: transparent;
    border: 2px solid $just-white;
    font-size: 2em;
    cursor: pointer;
    padding: 5px 10px;
    margin: 10px 0px;

    &:hover {
        color: $just-black;
        background: $just-white;
        border: 2px solid $just-black;
    }
}

.card {
    z-index: 1;
    transform: translateX(-100%);
    transition: all 0.5s ease-in-out;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    min-height: 100vh;
    width: 100vw;
    padding: 4rem 25%;
    font-size: 1.2em;
    overflow-y: scroll;

    color: $just-white;
    background-color: $just-black;

    @media screen and (max-width: $mobile-width) {
        padding: 2rem 1rem 10rem 1rem;
        margin: 0;
        // height: 140vh;
    }

    p {
        padding-top: 25px;
    }

    ul {
        padding: 25px;
    }
}

#bio {
    z-index: 3;
}

.card-nested {
    @extend .card;
    z-index: 2;
}

.card-open {
    transform: translateX(0);
    opacity: 1;
}

.folder {
    cursor: pointer;
    color: $just-white;
    border-bottom: 1px solid $just-white;
    text-align: center;
    margin: 20px;

    &:hover {
        transform: scale(110%);
    }
}

.white-line {
    background-color: $just-white;
    width: 100%;
    padding: 1px 0;
    margin: 10px 0;
}

.white-link {
    color: $just-white;

    &:hover {
        color: $terminal-green;
    }
}

.responsive-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;

    @media screen and (max-width: $mobile-width) {
        max-width: 90%;
    }
}